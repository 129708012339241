import consumer from "./consumer"

let callback; // declaring a variable that will hold a function later

consumer.subscriptions.create("NestProtectChannel", {
  connected: function () {
    console.log('Subscribed to the Nest Protect Channel')
  },
  disconnected: function() {

  },
  received: function (data) {
    if(data.colour && data.device_id){
      $('.nest-protect-icon[data_device_id="' + data.device_id + '"][data_property_id="' + data.property_id + '"] span').removeClass().addClass(data.colour);
    } else if(data.action){
      if(data.action == 'show' && data.view){
        $('.container-fluid .row .col-12').first().prepend(data.view);
      } else if(data.action == "unshow"){
        $('.alert.mark-property-safe-box').remove();
      }
    }
  }
});
