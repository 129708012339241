import consumer from "./consumer"

let callback; // declaring a variable that will hold a function later

function power_consumption_hash(real_time_power) {
  if (real_time_power < 0) {
    return { css_class:'text-info', usage_text:'exporting' }
  }  else if (real_time_power >= 0 && real_time_power < 3) {
    return { css_class:'text-success', usage_text:'low usage' }
  }  else if (real_time_power >= 3 && real_time_power < 7) {
    return { css_class: 'text-warning', usage_text: 'medium usage' }
  }  else if (real_time_power >= 7) {
    return { css_class: 'text-danger', usage_text: 'high usage' }
  } else {
    return { css_class: '', usage_text: 'Loading...' }
  }
}

if ($('meta[name=action-cable-url]').length){
  consumer.subscriptions.create("PropertyChannel", {
    connected: function () {
      console.log('Subscribed to the Property Channel')
    },
    disconnected: function() {

    },
    received: function (data) {
      if (data.switch){
        $(`div[data-property-id=${data.property_id}] .device-power[data-device-type=${data.device_type}][data-device-id=${data.device_id}]`)
          .bootstrapSwitch('disabled', data.disabled, true)
          .prop("disabled", data.disabled)
          .bootstrapSwitch('state', data.checked, true)
        }
      else if (data.utility){
        $(`div[data-property-id=${data.property_id}] .device-utility-permission[data-device-type=${data.device_type}][data-device-id=${data.device_id}]`)
          .bootstrapSwitch('state', data.checked, true)
          .prop("checked", data.checked)
        }
      else if (data.power){
        const power_consumption_data = power_consumption_hash(data.value)
        $(`div[data-property-id="${data.property_id}"] .real-time-power`)
          .attr('class', `d-flex real-time-power ${power_consumption_data.css_class}`)
        $(`div[data-property-id="${data.property_id}"] .real-time-power .power-counter`)
          .empty()
          .append(`<h4>${power_consumption_data.usage_text}</h4>`)
          .append(`<h2 class='text-body'>${data.value} kW</h2>`)
      }
      else if (data.weather){
        $(`div[data-property-id="${data.property_id}"] .weather-report .temperature`)
          .text(`${data.temperature} °C`)
        $(`div[data-property-id="${data.property_id}"] .weather-report .description`)
          .text(data.description)
        $(`div[data-property-id="${data.property_id}"] .weather-report .weather-icon`)
          .empty()
        if (data.description.includes('thunder')){
          $(`div[data-property-id="${data.property_id}"] .weather-report .weather-icon`)
            .addClass('wi fa-4x wi-thunderstorm')
          }
        else if (data.description.includes('drizzle')){
          $(`div[data-property-id="${data.property_id}"] .weather-report .weather-icon`)
            .addClass('wi fa-4x wi-sprinkle')
          }
        else if (data.description.includes('rain')){
          $(`div[data-property-id="${data.property_id}"] .weather-report .weather-icon`)
            .addClass('wi fa-4x wi-rain')
          }
        else if (data.description.includes('snow')){
          $(`div[data-property-id="${data.property_id}"] .weather-report .weather-icon`)
            .addClass('wi fa-4x wi-snow')
          }
        else if (data.description.includes('cloudy')){
          $(`div[data-property-id="${data.property_id}"] .weather-report .weather-icon`)
            .addClass('wi fa-4x wi-cloudy')
          }
        else if (data.description.includes('mist')){
          $(`div[data-property-id="${data.property_id}"] .weather-report .weather-icon`)
            .addClass('wi fa-4x wi-fog')
          }
        else if (data.description.includes('fog')){
          $(`div[data-property-id="${data.property_id}"] .weather-report .weather-icon`)
            .addClass('wi fa-4x wi-fog')
          }
        else if (data.description.includes('blizzard')){
          $(`div[data-property-id="${data.property_id}"] .weather-report .weather-icon`)
            .addClass('wi fa-4x wi-snow-wind')
          }
        else if (data.description.includes('clear')){
          $(`div[data-property-id="${data.property_id}"] .weather-report .weather-icon`)
            .addClass('wi fa-4x wi-day-sunny')
          }
        else if (data.description.includes('sunny')){
          $(`div[data-property-id="${data.property_id}"] .weather-report .weather-icon`)
            .addClass('wi fa-4x wi-day-sunny')
          }
        else if (data.description.includes('overcast')){
          $(`div[data-property-id="${data.property_id}"] .weather-report .weather-icon`)
            .addClass('wi fa-4x wi-day-sunny-overcast')
          }
        else if (data.description.includes('sleet')){
          $(`div[data-property-id="${data.property_id}"] .weather-report .weather-icon`)
            .addClass('wi fa-4x wi-sleet')
          }
        else if (data.description.includes('ice pellets')){
          $(`div[data-property-id="${data.property_id}"] .weather-report .weather-icon`)
            .addClass('wi fa-4x wi-hail')
          }
      }
    }
  });
}
