import consumer from "./consumer"

let callback; // declaring a variable that will hold a function later

if ($('meta[name=action-cable-url]').length){
  consumer.subscriptions.create("UtilityChannel", {
    connected: function () {
      console.log('Subscribed to the Utility Channel')
    },
    disconnected: function() {

    },
    received: function (data) {
      if (data.usage){
        $(`h2.total-energy-counter[data-utility-id="${data.utility_id}"]`)
          .text(data.value)
        }
      else if (data.available){
        $(`h2.dr-available-counter[data-utility-id="${data.utility_id}"]`)
          .text(data.value)
        }
      else if (data.events){
        $(`h2.dr-events-counter[data-utility-id="${data.utility_id}"]`)
          .text(data.value)
        }
    }
  });
}
